





























































































































































































































import { Component, Vue, Watch } from 'nuxt-property-decorator'
import Logo from '~/components/Logo.vue'
import WithRoot from '~/components/_common/WithRoot.vue'
import SettingThemeSwitch from '~/components/Setting/SettingThemeSwitch.vue'
import SettingLanguageSwitcher from '~/components/Setting/SettingLanguageSwitcher.vue'
import SearchWebsiteInput from '~/components/Other/SearchWebsiteInput.vue'
import { appModule, preferencesModule } from '~/store'
import { getNavItems } from '~/data/ui/navigationLists'
import services from '~/data/services'
import { lastEnBlogCreated, lastJaBlogCreated } from '~/data/blogs/conversion'
import { lastEnNewsCreated, lastJaNewsCreated } from '~/data/news/conversion'
import * as PostsUtils from '~/utils/PostsUtils'

@Component({
  name: 'TheHeader',
  components: {
    Logo,
    WithRoot,
    SettingThemeSwitch,
    SettingLanguageSwitcher,
    SearchWebsiteInput
  }
})
export default class extends Vue {
  windowSize = {
    x: 0,
    y: 0
  }

  serviceMenu: boolean = false
  serviceList = services
  navItemMenus = {}
  shouldHighlightBlog = false
  shouldHighlightNews = false

  lastPublishDates: any = {
    en: {},
    ja: {}
  }

  loginUrl = 'https://tc6.idioma.com/login' // https://tc6.idioma.com/client-portal

  @Watch('$route', { immediate: true })
  async routePathChanged() {
    await this.fetchLastPublishDates()
    this.checkHighlightBlog()
    this.checkHighlightNews()
  }

  get routePath() {
    return this.$route.path
  }

  get env() {
    return appModule.Env
  }

  get showAnniversary() {
    return this.windowSize.x !== 0 && this.windowSize.x > 1400
  }

  get smallButtons() {
    return (
      this.windowSize.x !== 0 &&
      this.windowSize.x >= 960 &&
      this.windowSize.x < 1200
    )
  }

  get navItems() {
    // console.log(getNavItems(this.$i18n.locale))
    return getNavItems(this.$i18n.locale)
  }

  get dark() {
    return preferencesModule.DarkTheme
  }

  async fetchLastPublishDates() {
    const language = PostsUtils.getLangToLoadByLocale(this.$i18n.locale)
    const dates = await this.$axios.$get(
      `/api/posts-last-publish-dates/${language}`
    )
    this.lastPublishDates[language] = dates
  }

  checkHighlightBlog() {
    const language = PostsUtils.getLangToLoadByLocale(this.$i18n.locale)
    // because only ja are new from this date
    // return true if 1) should even compare 2) cookie not created yet or article is newer
    const dateOfStartComparing = new Date(
      Date.parse('2020-05-01')
    ).toISOString()
    const shouldEvenCompareCookies =
      this.lastPublishDates[language].Blog > dateOfStartComparing
    const lastVisitBlog = this.$cookies.get(`last_visit_blog_${language}`)
    if (shouldEvenCompareCookies) {
      this.shouldHighlightBlog =
        !lastVisitBlog || lastVisitBlog < this.lastPublishDates[language].Blog
      return
    }
    this.shouldHighlightBlog = false
  }

  checkHighlightNews() {
    const language = PostsUtils.getLangToLoadByLocale(this.$i18n.locale)
    // because only ja are new from this date
    // return true if 1) should even compare 2) cookie not created yet or article is newer
    const dateOfStartComparing = new Date(
      Date.parse('2020-05-01')
    ).toISOString()
    const shouldEvenCompareCookies =
      this.lastPublishDates[language].News > dateOfStartComparing
    const lastVisitNews = this.$cookies.get(`last_visit_news_${language}`)
    if (shouldEvenCompareCookies) {
      this.shouldHighlightNews =
        !lastVisitNews || lastVisitNews < this.lastPublishDates[language].News
      return
    }
    this.shouldHighlightNews = false
  }

  toggleMobileNav() {
    appModule.toggleMobileNav()
    // appModule.setMobileNav(!appModule.MobileNav)
  }

  onResize() {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight }
  }

  mounted() {
    this.onResize()
  }
}
