
















import { Component, Vue, Watch, Prop } from 'nuxt-property-decorator'
import ThemeMixin from '~/mixins/ThemeMixin'
import { preferencesModule } from '~/store'

@Component({
  name: 'SettingThemeSwitch',
  components: {},
  mixins: [ThemeMixin]
})
export default class extends Vue {
  @Prop({ default: true }) tooltip!: boolean
  @Prop({ default: false }) small!: boolean

  get dark() {
    // return this.$vuetify.theme.dark
    return preferencesModule.DarkTheme
  }

  toggle(event) {
    if (event) event.stopPropagation()
    // console.log(this.$vuetify.theme.dark)
    // this.$vuetify.theme.dark = !this.dark
    preferencesModule.toggleDarkTheme()
  }
}
