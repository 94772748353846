var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"shadow-blur px-3",attrs:{"hide-on-scroll":true,"scroll-threshold":"200","flat":"","fixed":"","app":"","color":"white","height":"80"}},[_c('v-toolbar-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('nuxt-link',{staticClass:"no-underline",attrs:{"to":_vm.localePath('index')}},[_c('Logo',{staticStyle:{"vertical-align":"middle"},attrs:{"width":"100"}}),_vm._v(" "),(_vm.env.website == 'idiomaservice')?_c('div',{staticClass:"text-sm text--primary font-extrabold text-uppercase leading-tight"},[_vm._v("\n          SERVICES")]):_vm._e()],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAnniversary),expression:"showAnniversary"},{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"text-xs text--secondary font-semibold leading-snug ml-4 hidden-md-and-down"},[_c('div',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.$tt('Logo.Experts')))]),_vm._v(" "),_c('div',{staticClass:"font-cooper green--text darken-1 text-sm mt-1"},[_vm._v("\n          "+_vm._s(_vm.$tt('Logo.Anniversary'))+"\n        ")])])],1)],1),_vm._v(" "),_c('v-toolbar-items',{staticClass:"align-center hidden-sm-and-down",style:({
      marginLeft: _vm.smallButtons ? '0px' : '30px'
    })},[(_vm.env.website == 'idiomaservice')?_c('v-btn',{attrs:{"ripple":false,"text":"","href":"https://www.idioma.com/"}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("fa-home")]),_vm._v(_vm._s(_vm.$t('Navigation.Home'))+"\n    ")],1):_vm._e(),_vm._v(" "),(_vm.env.website == 'idiomaservice')?_c('v-divider',{staticClass:"v-divider--vertical-mini mx-4",attrs:{"vertical":""}}):_vm._e(),_vm._v(" "),_c('v-menu',{attrs:{"open-on-hover":true,"nudge-top":0,"offset-y":true,"content-class":"shadow-blur","eager":true,"allow-overflow":true,"attach":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({class:{
            'ml-4': true,
            'v-btn--active-disabled': _vm.env.website === 'idiomaservice'
          },attrs:{"to":_vm.env.website === 'idiomaservice'
              ? _vm.localePath('/')
              : _vm.localePath('/services'),"active-class":"","ripple":false,"text":""}},on),[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("fa-th")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('Navigation.Services')))]),_vm._v(" "),_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v("fa-caret-down")])],1)]}}]),model:{value:(_vm.serviceMenu),callback:function ($$v) {_vm.serviceMenu=$$v},expression:"serviceMenu"}},[_vm._v(" "),_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((_vm.serviceList),function(item,key){return _c('v-list-item',{key:key,attrs:{"to":_vm.localePath(item.Path),"ripple":false}},[_c('v-list-item-title',[_vm._v(_vm._s(item.Name))])],1)}),1)],1),_vm._v(" "),(_vm.env.website == 'idioma')?[_vm._l((_vm.navItems),function(item,i){return [(item.Children)?_c('v-menu',{key:i,attrs:{"open-on-hover":true,"nudge-top":0,"offset-y":true,"allow-overflow":true,"content-class":"shadow-blur","transition":"slide-y-transition","eager":true,"attach":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(item.Children)?_c('v-btn',_vm._g({attrs:{"to":_vm.localePath(item.Path),"ripple":false,"small":_vm.smallButtons,"text":""}},on),[_c('span',[_vm._v(_vm._s(_vm.$t(("Navigation." + (item.Key)))))]),_vm._v(" "),_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v("fa-caret-down")])],1):_vm._e()]}}],null,true),model:{value:(_vm.navItemMenus[item.Key]),callback:function ($$v) {_vm.$set(_vm.navItemMenus, item.Key, $$v)},expression:"navItemMenus[item.Key]"}},[_vm._v(" "),_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((item.Children),function(item,j){return _c('v-list-item',{key:j,attrs:{"to":_vm.localePath(item.Path),"href":item.Href,"target":item.Target,"ripple":false}},[_c('v-list-item-title',[_vm._v(_vm._s(item.Text || _vm.$t(("Navigation." + (item.Key)))))])],1)}),1)],1):_c('WithRoot',{key:i,attrs:{"show-if":(item.Key === 'Blog' && _vm.shouldHighlightBlog) ||
            (item.Key === 'News' && _vm.shouldHighlightNews)}},[_c('v-badge',{attrs:{"color":"green","offset-x":"35","offset-y":"30","content":_vm.$t('Common.Badges.New')}},[_c('v-btn',{attrs:{"to":_vm.localePath(item.Path),"ripple":false,"height":"80","small":_vm.smallButtons,"text":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(("Navigation." + (item.Key)))))])])],1)],1)]})]:[_c('v-btn',{attrs:{"to":_vm.localePath('/contact-us'),"ripple":false,"height":"80","small":_vm.smallButtons,"text":""}},[_c('span',[_vm._v(_vm._s(_vm.$t("Navigation.ContactUs")))])])]],2),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"ripple":false,"color":"green darken-2","text":"","href":_vm.loginUrl,"small":_vm.smallButtons}},[_c('v-icon',{attrs:{"left":"","small":_vm.smallButtons}},[_vm._v("fa-user-circle")]),_vm._v(" "),_c('span',{staticClass:"hidden-sm-and-down font-semibold"},[_vm._v(_vm._s(_vm.$t("Navigation.Login")))])],1),_vm._v(" "),(!_vm.$vuetify.breakpoint.sm)?_c('SettingLanguageSwitcher',{staticClass:"ml-1"}):_vm._e(),_vm._v(" "),_c('v-btn',{staticClass:"ml-3 px-2 hidden-md-and-up",attrs:{"ripple":false,"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleMobileNav($event)}}},[_c('v-icon',[_vm._v("fa-bars")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }