
































import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'

@Component({
  name: 'SearchWebsiteInput'
})
export default class extends Vue {
  queryText: string = ''

  submit() {
    console.log(this.queryText)
  }
}
